/* eslint-disable */
import { $themeColors } from '@themeConfig'

// colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  lineChartSimple: {
    series: [
      {
        data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.warning],
      },
      colors: [$themeColors.warning],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${
            data.series[data.seriesIndex][data.dataPointIndex]
          }%</span></div>`
        },
      },
      xaxis: {
        categories: [
          '7/12',
          '8/12',
          '9/12',
          '10/12',
          '11/12',
          '12/12',
          '13/12',
          '14/12',
          '15/12',
          '16/12',
          '17/12',
          '18/12',
          '19/12',
          '20/12',
          '21/12',
        ],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  lineAreaChartSpline: {
    series: [
      {
        name: 'ADAUSDT',
        data: [ 
          [1484505000000, 100002000], 
          [1484591400000, 10379978],
          [1484591400000, 20379978],
          [1484591400000, 50379978],
          [1484591400000, 60379978],
          [5484591400000, 300379978],
        ],
      },
      {
        name: 'ALGOUSDT',
        data: [ 
          [1484505000000, 150000000], 
          [1484591400000, 160379978],
          [1484591400000, 160379978],
          [1484591400000, 160379978],
          [1484591400000, 160379978],
          [1484591400000, 160379978],
        ],
      },
    ],
    chartOptions: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.warning],
      },
      colors: [$themeColors.warning],

      title: {
        text: 'Relatório de desempenho',
        align: 'left',
      },
      stroke: {
        show: false,
        curve: 'straight',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val / 1000000).toFixed(0)
          },
        },
        title: {
          text: 'Price',
        },
      },
      tooltip: {
        shared: false,
      },
      colors: [chartColors.area.series3, chartColors.area.series2, chartColors.area.series1],
    },
  },

  earningsBarGraph: {
    series: [{
      data: [100, 200, 140, 400]
    }],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          columnWidth: '10%',
          colors: {
            backgroundBarColors: [
              '#313A49',
              '#313A49',
              '#313A49',
              '#313A49',
              '#313A49',
              '#313A49',
              '#313A49',
              '#313A49',
            ],
            backgroundBarRadius: 7,
          },
        }
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: ['23-11','23-12', '24-01', '24-02'],
        lines: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        // opposite: isRtl,
        categories: [''],
        labels: {
          show: false
        },
        
      },
      fill: {
        opacity: 1,
      },
    },
  },

  earningsTotalDonutChart: {
    series: [100, 100],
    chartOptions: {
      legend: {
        show: false,
      },
      colors: [
        '#288D70',
        '#2B7461',
      ],
      dataLabels: {
        enabled: false,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '12px',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '12px',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              total: {
                show: true,
                fontSize: '12px',
                label: 'Total',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${val.config.series[0]}%`
                },
              },
            },
          },
        },
      },
      labels: ['Total', '']
    },
  },

  columnChart: {
    series: [
      {
        name: 'Apple',
        data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
      },
      {
        name: 'Samsung',
        data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series1, chartColors.column.series2],
      plotOptions: {
        bar: {
          columnWidth: '15%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12'],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },

  barChart: {
    series: [
      {
        data: [700, 350, 480, 600, 210, 550, 150],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: $themeColors.info,
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['MON, 11', 'THU, 14', 'FRI, 15', 'MON, 18', 'WED, 20', 'FRI, 21', 'MON, 23'],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

}
