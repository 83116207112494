<template>

  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="empresaLogo"
        alt="logo"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Under Maintenance 🛠
        </h2>

        <p class="mb-3">
          Sorry for the inconvenience but we're performing some maintenance at the moment
        </p>
      </div>

      <b-card no-body>
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Line Chart
            </b-card-title>
            <b-card-sub-title>Commercial networks</b-card-sub-title>
          </div>
          <!--/ title and subtitle -->

          <!-- datepicker -->
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
            />
          </div>
          <!-- datepicker -->
        </b-card-header>

        <b-card-body>
          <vue-apex-charts
            type="area"
            height="400"
            :options="apexChatData.lineAreaChartSpline.chartOptions"
            :series="apexChatData.lineAreaChartSpline.series"
          />
        </b-card-body>
      </b-card>

    </div>
  </div>
</template>

<script>
import store from '@/store'
import {
  BCard, BLink, BImg, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'
import performanceStoreModule from './performanceStoreModule'

const PERFORMANCE_STORE_MODULE_NAME = 'publico-performance'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    flatPickr,
  },
  data() {
    return {
      loadingOverlay: false,
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },

  created() {
    if (!store.hasModule(PERFORMANCE_STORE_MODULE_NAME)) store.registerModule(PERFORMANCE_STORE_MODULE_NAME, performanceStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(PERFORMANCE_STORE_MODULE_NAME)) store.unregisterModule(PERFORMANCE_STORE_MODULE_NAME)
  },

  setup() {
    const { empresa, empresaLogo } = useAppConfig()
    const { appName } = $themeConfig.app

    return {
      empresa,
      empresaLogo,
      appName,
    }
  },

  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
